import React from 'react';
import { Layout } from 'antd';
import WebHeader from './components/WebHeader';
import WebContent from './components/WebContent';
import WebFooter from './components/WebFooter';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/icons/fontawesome.css';
import './css/icons/light.css';
import './css/icons/brands.css';
import './css/icons/solid.css';

function App() {
  return (
    <div className="App">
      <Layout style={{padding: '0vw 5vw'}}>
        <WebHeader />
        <WebContent />
        <WebFooter />
      </Layout>
    </div>
  );
}

export default App;
