import React, { Fragment } from 'react';
import info_bg from './../media/services_blob_bg.png'

const infobox_style = {
    width: 250,
    height: 130,
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    padding: '1%',
    backgroundImage: `url(${info_bg})`,
    backgroundPosition: 'left',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat'
}

const title_style = {
    fontSize: '.9em',
    fontWeight: 'bold'
}

const info_style = {
    fontSize: '.7em'
}

const InfoBox = ({ icon, title, info, invert }) => {
    return (
        <div className="py-3" style={infobox_style}>
            <img src={icon} style={{ width: 60, float: 'left' }} />
            <div className="text-break">
                {
                    invert ? <Fragment>
                                <p className="text-break m-0" style={info_style}>{info}</p>
                                <p className="mb-0" style={title_style}>{title}</p>
                            </Fragment>
                        :   <Fragment>
                                <p className="mb-0" style={title_style}>{title}</p>
                                <p className="text-break m-0" style={info_style}>{info}</p>
                            </Fragment>
                }

            </div>
        </div>
    )
}

export default InfoBox;