import React from 'react';
import { Layout } from 'antd';


const { Footer } = Layout;

const WebFooter = () => {
    return (
        <Footer style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 10 }}>ServerSide © 2020</Footer>
    );
};

export default WebFooter;