import React, { useState } from 'react';
import { Form, Button, Input, Result, Spin } from 'antd';
import logo from './../media/logo.png'
import { UserOutlined, MailOutlined, PhoneOutlined, LaptopOutlined, MessageOutlined, BackwardOutlined } from '@ant-design/icons';
import { Row, Col } from 'react-flexbox-grid';
import FadeIn from 'react-fade-in';

const logo_style = {
    width: 150,
}

const required_field = '❌ Campo necesario.';
const ContactForm = () => {
    const [state, setState] = useState('not-sent');
    const submit = e => {
        setState('loading');
        window.Email.send({
            SecureToken: "cb83f201-e6dc-4227-9402-ce55a7bd56cb",
            To: 'info@serverside.es',
            From: "info@serverside.es",
            Subject: "FORMULARIO DE CONTACTO - SERVERSIDE",
            Body: e.message
        }).then(
            message => {
                if (message === 'OK') {
                    setState('sent')
                    setTimeout(() => {
                        setState('not-sent')
                    }, 5000)
                } else {
                    setState('error');
                    setTimeout(() => {
                        setState('not-sent')
                    }, 5000)
                }
            }
        );
    }

    const goBack = () => setState('not-sent');
    const success = <FadeIn>
        <Result status="success"
            title={<div>
                <h5>¡GRACIAS POR CONTACTARNOS!</h5>
                <img id="logo" alt="Server Side" src={logo} style={logo_style} />
            </div>} subTitle="En cuanto leamos su consulta nos pondremos en contacto con usted." extra={[<Button onClick={() => goBack()} type="primary" key="console" icon={<BackwardOutlined />}>Volver</Button>]} /></FadeIn>

    const error = <FadeIn>
        <Result status="error"
            title={<div>
                <h5>¡HA OCURRIDO UN ERROR AL ENVIAR EL FORMULARIO!</h5>
                <img id="logo" alt="Server Side" src={logo} style={logo_style} />
            </div>} extra={[<Button onClick={() => goBack()} type="primary" key="console" icon={<BackwardOutlined />}>Volver</Button>]} /></FadeIn>

    const form = <Spin spinning={state === 'loading' ? true : false}>
        <FadeIn>
            <Form id="contact-form" onFinish={submit} className="p-3">
                <Row>
                    <Col xs={12} lg={12}>
                        <FadeIn delay={400}>
                            <h4 className="d-flex align-items-center justify-content-center"><LaptopOutlined /><span>CONTACTO</span></h4>
                        </FadeIn>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} lg={4}>
                        <Form.Item name="name" className="mb-1"
                            rules={[{ required: true, message: required_field }]}>
                            <Input name="name" prefix={<UserOutlined style={{ color: '#d9d9d9' }} />} placeholder="Nombre" />
                        </Form.Item>
                    </Col>
                    <Col xs={12} lg={4}>
                        <Form.Item name="email" className="mb-1"
                            rules={[{ required: true, message: required_field }]}>
                            <Input name="email" prefix={<MailOutlined style={{ color: '#d9d9d9' }} />} placeholder="Email" />
                        </Form.Item>
                    </Col>
                    <Col xs={12} lg={4}>
                        <Form.Item name="phone" className="mb-1">
                            <Input name="phone" prefix={<PhoneOutlined style={{ color: '#d9d9d9' }} />} placeholder="Teléfono" />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item name="message" className="mb-1"
                    rules={[{ required: true, message: required_field }]}>
                    <Input.TextArea name="message" placeholder="Mensaje" />
                </Form.Item>
                <Form.Item>
                    <Button htmlType="submit" className="d-flex align-items-center justify-content-center" block type="primary" icon={<MessageOutlined />}>
                        ENVIAR
                    </Button>
                </Form.Item>
            </Form>
        </FadeIn>
    </ Spin>

    switch (state) {
        case 'sent':
            return success;
        case 'error':
            return error;
        default:
            return form;
    }
}

export default ContactForm;