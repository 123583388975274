import React from 'react';
import { Layout, Divider, Button, Icon } from 'antd';
import { Row, Col } from 'react-flexbox-grid'
import InfoBox from '../elements/InfoBox';
import Wave from 'react-wavify';
import FadeIn from 'react-fade-in';

import icon_server from './../media/icon_server.png';
import icon_settings from './../media/icon_settings.png';
import icon_lock from './../media/icon_lock.png';
import icon_checklist from './../media/icon_checklist.png';
import Projects from '../elements/Projects';
import ContactForm from '../elements/ContactForm';
import { RightOutlined, RadarChartOutlined } from '@ant-design/icons';

const { Content } = Layout;
const content_style = {
    backgroundColor: '#FFF',
    margin: '0vh auto',
    borderRadius: 10
};

const services = [
    { title: "SOLUCIONES", info: "EN VIRTUALIZACIÓN DE SERVIDORES Y SERVICIOS", icon: icon_server, },
    { title: "ADMINISTRACIÓN", info: "EN VIRTUALIZACIÓN DE SERVIDORES Y SERVICIOS", icon: icon_settings, },
    { title: "SEGURIDAD", info: "AUDITORIAS DE", icon: icon_lock, invert: true },
    { title: "INFRAESTRUCTURAS", info: "PTIMIZACIÓN DE", icon: icon_checklist, invert: true },
]

const projects = [
    {
        title: "SEARCH", subtitle: "'N GO'",
        info:
            <div className="project-container-info">
                <p><i className="fal fa-map-marked-alt" /> Base de datos en tiempo real integrada con <strong>Google Maps</strong>.</p>
                <p><i className="fal fa-location" /> Selecciona personalizada de zonas de busuqueda.</p>
                <p><i className="fal fa-key-skeleton" /> Optimización de Keywords.</p>
                <p><i className="fal fa-arrows-h" /> Flexibilidad de exportacion de datos.</p>
                <p><i className="fal fa-alarm-exclamation" /> Actualización en tiempo real de resultados.</p>
                <p><i className="fal fa-poll-h" /> Integración con gestor de resultados para organización de funcionalidades.</p>
                <p><i className="fal fa-id-card" /> Analisis de contactos en directorios de busquedas.</p>
            </div>
    },
    {
        title: "DIGITAL", subtitle: "DOCTOR",
        info:
            <div className="project-container-info">
                <p><i className="fal fa-user-md" /> Consultorio online (plataforma aplicada a entornos medicos).</p>
                <p><i className="fal fa-users" /> Gestion de perfiles.</p>
                <p><i className="fal fa-calendar-alt" /> Calendario de citas.</p>
                <p><i className="fal fa-file-medical-alt" /> Recetas inteligentes.</p>
                <p><i className="fal fa-history" /> Historia clínica.</p>
                <p><i className="fal fa-tasks" /> Gestiona tus pacientes.</p>
                <p><i className="fal fa-clipboard-list-check" /> Listas de Trabajo.</p>
                <p><i className="fal fa-clinic-medical" /> Administración de tu organización.</p>
                <p><i className="fal fa-laptop-code" /> Compatibilidad con todos los dispositivos.</p>
                <p><i className="fab fa-aws" /> Alojamiento en servidores Amazon.</p>
                <p><i className="fal fa-clock" /> Soporte en linea 24x7.</p>
                <hr />
                <p className="m-0 font-weight-bold"><i className="fas fa-check text-success" /> Trabajá desde donde quieras y estés donde estés.</p>
                <p className="m-0 font-weight-bold"><i className="fas fa-check text-success" /> Cancelá turnos on line cuando quieras.</p>
                <p className="m-0 font-weight-bold"><i className="fas fa-check text-success" /> Legal y seguro en nuestros servidores en Amazon.</p>
            </div>
    },
    {
        title: "ELKOBAN", subtitle: "",
        info:
            <div className="project-container-info">
                <p><i className="fal fa-tachometer-alt-fast" /> Dashboard de monitoreo aplicado a la seguridad de sistemas informativos en timepo real y funcionalidad active responsive <strong>(opensource)</strong></p>
            </div>
    },
    {
        title: "ISP", subtitle: "",
        info:
            <div className="project-container-info">
                <p><i className="fal fa-server" /> Base de datos en tiempo real integrada con <strong>Google Maps</strong>.</p>
            </div>
    },
]


const WebContent = () => {
    return (
        <Content style={content_style}>
            <Row className="py-5" around="lg">
                <Col lg={6}>
                    <Row>
                        {
                            services.map((service, index) =>
                                <Col lg={6} key={`service-${index}`}>
                                    <FadeIn key={`fade-service-${index}`} delay={Number(`${index}00`)}>
                                        <InfoBox title={service.title} info={service.info} icon={service.icon} invert={service.invert} key={`service-item-${index}`} />
                                    </FadeIn>
                                </Col>
                            )
                        }
                    </Row>

                    <Row className="pt-5 pl-3 pr-3">
                        <Col xs={12} lg={12}>
                            <FadeIn delay={400}>
                                <h4 className="d-flex align-items-center justify-content-center"><RadarChartOutlined /><span>PROYECTOS</span></h4>
                            </FadeIn>
                        </Col>
                        {
                            projects.map((project, index) =>
                                <Col className="text-center p-2" xs={6} lg={3} key={`project-${index}`}>
                                    <FadeIn key={`fade-project-${index}`} delay={Number(`${index}00`)}>
                                        <Projects title={project.title} subtitle={project.subtitle} info={project.info} key={`project-item-${index}`} />
                                    </FadeIn>
                                </Col>
                            )
                        }
                    </Row>

                    <Row className="pt-5">
                        <Col xs={12} lg={12}>
                            <ContactForm />
                        </Col>
                    </Row>

                </Col>
            </Row>
            <Row className="pt-4">
                <Col xs={12} lg={12}>
                    <Row className="d-flex align-items-center justify-content-center"
                        style={{ position: 'absolute', width: '100%', top: '80%', fontSize: 7 }}>
                        <Col className="d-flex align-items-center justify-content-center" xs={6} lg={6}>
                            <RightOutlined className="pr-1" style={{ fontSize: '1.5rem', color: '#FF8900' }} />
                            <p className="mb-0"> <strong>ESPAÑA</strong> | PALMA DE MALLORCA <br />000 000 000</p>
                        </Col>
                        <Col className="d-flex align-items-center justify-content-center" xs={6} lg={6}>
                            <RightOutlined className="pr-1" style={{ fontSize: '1.5rem', color: '#FF8900' }} />
                            <p className="mb-0"> <strong>ARGENTINA</strong> | BUENOS AIRES <br />000 000 000</p>
                        </Col>
                    </Row>
                    <Wave fill='#EFF2F4' paused={false} options={{ height: 40, amplitude: 30, speed: 0.15, points: 3 }} />
                </Col>
            </Row>
        </Content>
    );
}

export default WebContent;