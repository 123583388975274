import React from 'react';
import { Button, Icon, Popover } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';

const Projects = ({ title, subtitle, info }) => {
    return (
        <Popover content={info} title={`${title} ${subtitle}`}>
            <Button style={{ height: 100, width: 100 }} shape="circle" type="primary" size="large">
                <p className="font-weight-bold m-0">{title}</p>
                <p className="m-0">{subtitle}</p>
                <CaretDownOutlined />
            </Button>
        </Popover>
    )
}

export default Projects;