import React from 'react';
import { Layout, Menu, Dropdown } from 'antd';
import { Row, Col } from 'react-flexbox-grid';
import logo from './../media/logo.png';
import { MenuOutlined } from '@ant-design/icons';

const header_style = {
    //position: 'fixed', 
    zIndex: 1, 
    margin: 'auto 10vh',
    padding: 0
}

const logo_style = {
    width: 150,
    float: 'left'
}

const menu_style = {
    float: 'right'
}


const { Header } = Layout;
const web_menu = (
    <Menu>
        <Menu.Item key="0">
            <a href="#">Inicio</a>
        </Menu.Item>
        <Menu.Item key="1">
            <a href="#">Stack</a>
        </Menu.Item>
        <Menu.Item key="2">
            <a href="#">Contácto</a>
        </Menu.Item>
    </Menu>
)

const WebHeader = () => {
    return (
        <Header className="pt-3" style={header_style}>
            <Row around="lg">
                <Col className="d-flex justify-content-between align-items-center" lg={6}>
                    <img id="logo" alt="Server Side" src={logo} style={logo_style} />
                    <Dropdown style={menu_style} overlay={web_menu}>
                    <MenuOutlined />
                    </Dropdown>
                </Col>
            </Row>
        </Header>
    );
}

export default WebHeader;